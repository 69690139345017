import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';


const getSuggestionData = async (value, setSuggestions) => {
    //console.log("Value length----------->" + value.length)
    if (value != null && value.length > 1) {
        const response = await fetch(process.env.REACT_APP_API_HOST + `/locateCity.json?name=${value}`);
        response.json().then(res => {
            setSuggestions(res);
        });
    } else {
        setSuggestions([]);
    }
}

const MyAutocomplete = (props) => {
    const [inputValue, setInputValue] = useState('');
    const [selectValue, setSelectValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const setMapView = props.setMapView;

    React.useEffect(() => {
        if (inputValue && inputValue !== "") {
            getSuggestionData(inputValue, setSuggestions);
        }
        if(inputValue === "") {
            setSuggestions([]);
        }
    }, [inputValue])

    React.useEffect(() => {
        //console.log("selectValue: " + selectValue)
        if (selectValue && selectValue.center) {
            let mapCenter = selectValue.center;
            setMapView({position:[mapCenter[1],mapCenter[0]]});
            setSelectValue(null);
            setInputValue("");
        }
    }, [selectValue, suggestions, setMapView])

    return (
        <Box>
            <Box sx={{ m: 1}}>
                <Autocomplete
                    size="small"
                    sx={{ m: 0, p: 0 }}
                    id="pantocity-search"
                    getOptionLabel={(option) =>
                        typeof option === 'string' ? option : getOptionValue(option)
                    }
                    filterOptions={(x) => x}
                    options={suggestions}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    value={selectValue}
                    noOptionsText="No locations"
                    onChange={(event, newValue) => {
                        setSelectValue(newValue)
                    }}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue)
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Pan to City" fullWidth size="small" />
                    )}
                    isOptionEqualToValue={(option, value) => {
                        return getOptionValue(option) === getOptionValue(value)
                    }
                    }
                />
            </Box>
        </Box>


    );
};

const getOptionValue = (option) => {
    if (option === "") return "";
    return option?.name + ", " + option?.fips.state;
}

export default MyAutocomplete;
