import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import CardContent from "@mui/material/CardContent";
import { titleCase } from "title-case";
import { Accordion, Typography, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionSummaryReverse } from "../../components/Accordion";

const weatherWarningIcons = {
  "flash flood":
    process.env.PUBLIC_URL +
    "/mapicons/WeatherWarning/Flash_Flood_Warning_v19.png",
  thunderstorm:
    process.env.PUBLIC_URL +
    "/mapicons/WeatherWarning/Thunderstor_Warning_v19.png",
  tornado:
    process.env.PUBLIC_URL + "/mapicons/WeatherWarning/Tornado_Warning_v19.png",
};

const style = (feature) => {
  let type = feature.properties.type;
  //console.log("type" + type)
  const defaultStyle = {
    color: "#640000",
    weight: 2,
    fillOpacity: 0.3,
    fillColor: "#640000",
  };
  switch (type) {
    case "Tornado warning":
      return { ...defaultStyle, fillColor: "#ff0000" };
    case "Severe thunderstorm warning":
      return { ...defaultStyle, fillColor: "#ffa500" };
    case "Flash flood warning":
      return { ...defaultStyle, fillColor: "#8b0000" };
    default:
      return { ...defaultStyle, fillColor: "#000000" };
  }
};

const PopupTemplate = (props) => {
  const feature = props.feature;

  return <WeatherWarning feature={feature} {...props} />;
};

const WeatherWarning = (props) => {
  const feature = props.feature;
  const properties = feature.properties;

  return (
    <Card sx={{ borderRadius: 3 }}>
      <CardHeader
        avatar={
          <Avatar
            alt="Weather warning"
            src={
              process.env.PUBLIC_URL +
              "/mapicons/WeatherWarning/Weather_Warning_v16.png"
            }
            sx={{ width: 21, height: 21 }}
            aria-label="Weatherwarning"
          ></Avatar>
        }
        title={<font className="layer-title"> Weather warning </font>}
        className={"styledtablerow"}
        sx={{ pb: 0, paddingTop: 1, paddingLeft: 1 }}
      />
      <CardContent
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          overflow: "auto",
        }}
      >
        <Table size="small">
          <TableRow>
            {/* <TableCell className={"layertablecellLeft"}>
                            Type
                        </TableCell> */}
                        <TableCell className={"layertablecellLeft"}>
                            {properties.headline}
                        </TableCell>
                    </TableRow>
                    <TableRow className={"styledtablerow"}>
                        <TableCell className={"layertablecellLeft"}>
                            {properties.summary} 
                        </TableCell>
                    </TableRow>
                    <div>
                        <Accordion>
                            <AccordionSummaryReverse
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls='popup-description'
                                id='header'
                                className='controls-header'
                                sx={{mt:0}}
                            >
                            <Typography variant='body3'> Click here for more details</Typography>
                            </AccordionSummaryReverse>
                            <AccordionDetails>
                                <Table size='small' sx={{marginBottom:-50}}>
                                    {properties.sections.map(section=>{
                                    if(section.heading!==null && section.heading!=="") { 
                                     return(   
                                    <TableRow>
                                        
                                        <TableCell className={"layertablecellLeft"}>
                                    
                                        {section.heading}
                                        </TableCell>
                                        <TableCell className={"layertablecellRight"}>
                                            {section.description}
                                        </TableCell>
                                        
                                    </TableRow>
                                    )
                                }
                                else if((section.heading==null || section.heading=="" ) &&  section.description!==null && section.description!==""){
                                    return (<TableRow>
                                        
                                        <TableCell colSpan="2" className={"layertablecellRight"}>
                                            {section.description}
                                        </TableCell>
                                        
                                    </TableRow>
                                    )
                                }
                            }
                            
                                    )}
                                </Table>
                                
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </Table>
            </CardContent>
        </Card>
    )
}

const LegendTemplate = (props) => {
  return (
    <Box sx={{ display: "contents", height: "20px" }}>
      {Object.keys(weatherWarningIcons).map((key, index) => {
        let image = weatherWarningIcons[key];
        return (
          <Box sx={{ ml: 7, mr: -7, display: "flex" }} key={key}>
            <Box
              sx={{
                backgroundImage: `url(${image})`,
                backgroundSize: "contain",
                width: "20px",
                backgroundRepeat: "no-repeat",
                marginTop: "2px",
                height: "13px",
              }}
            ></Box>
            <Box sx={{ mr: 1, ml: 1, fontSize: 12 }}>{titleCase(key)}</Box>
          </Box>
        );
      })}
    </Box>
  );
};

const module = {
  name: "Weather Warnings",
  endpoint: "/weatherMap.json",
  showOnMap: true,
  polygonLayer: true,
  PopupTemplate: PopupTemplate,
  updateDelay: 180,
  mapLayerIcon:
    process.env.PUBLIC_URL + "/mapicons/WeatherWarning/Weather_Warning_v16.png",
  LegendTemplate: LegendTemplate,
  style: style,
  maxZoom: 4,
  zIndex: 606,
};

export default module;
